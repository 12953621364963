import React from "react";
import { Link } from "gatsby";
import Smartphone from "../../images/icons/smartphone.svg";
import Mail from "../../images/icons/mail.svg";
import Facebook from "../../images/icons/facebook.svg";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function Footer() {
  const query = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pfr: file(relativePath: { eq: "pfr.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <footer className="footer-part">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-5">
            <div className="single-footer-part company-info-text">
              <Link to="/" className="footer-logo">
                <Img fluid={query.logo.childImageSharp.fluid} alt="Logo" />
              </Link>
              <p>
                Zaopatrzymy Twoją restaurację, kawiarnię lub biuro w wysokiej
                jakości produkty. Posiadamy szeroki asortyment ekspresów do kawy
                spełniających oczekiwania najbardziej wymagających odbiorców.
              </p>
              <Img fluid={query.pfr.childImageSharp.fluid} alt="PFR" />
            </div>
          </div>
          <div className="col-sm-4 col-md-6 col-lg-2 offset-lg-2">
            <div className="single-footer-part">
              <h4>Oferta</h4>
              <ul className="list-unstyled">
                <li>
                  <Link to="/kawy">Kawy</Link>
                </li>
                <li>
                  <Link to="/herbaty">Herbaty</Link>
                </li>
                <li>
                  <Link to="/ekspresy">Ekspresy</Link>
                </li>
                <li>
                  <Link to="/serwis">Serwis</Link>
                </li>
                <li>
                  <Link to="/szkolenia">Szkolenia</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-4 col-md-6 col-lg-3">
            <div className="single-footer-part">
              <h4>Szybki kontakt</h4>
              <ul className="list-unstyled">
                <li>
                  <div className="footer-contact-icon">
                    <Smartphone />
                  </div>
                  606 172 993
                </li>
                <li>
                  <div className="footer-contact-icon">
                    <Facebook />
                  </div>
                  <a
                    href="https://www.facebook.com/nieladakawa/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    nieladakawa
                  </a>
                </li>
                <li>
                  <div className="footer-contact-icon">
                    <Mail />
                  </div>
                  <div>
                    <a href="mailto:biuro@nieladakawa.pl">
                      biuro@nieladakawa.pl
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
