import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function Header() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [stickyHeaderClass, setStickyHeaderClass] = useState("");
  const query = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 104, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const handleScroll = () => {
    const windowTop = window.scrollY + 1;
    if (windowTop > 50) {
      setStickyHeaderClass("menu-fixed animate__animated animate__fadeInDown");
    } else {
      setStickyHeaderClass("");
    }
  };

  const toggleMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <header
      className={`main-menu 
        ${stickyHeaderClass} 
        ${isMobileMenuOpen ? "menu-opened" : ""}`}
    >
      <div className="container">
        <nav className="navbar">
          <button
            className={`mobile-toggler ${isMobileMenuOpen ? "act" : ""}`}
            onClick={() => toggleMenu()}
            type="button"
            data-toggle="collapse"
            aria-label="Toggle navigation"
          >
            <div className="menu-icon" />
          </button>

          <Link to="/" className="main-logo">
            <Img
              fluid={query.logo.childImageSharp.fluid}
              alt="Logo"
            />
          </Link>

          <div className={`menu-container ${isMobileMenuOpen ? "act" : ""}`}>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/o-nas" className="nav-link" activeClassName="active">
                  O nas
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/kawy" className="nav-link" activeClassName="active">
                  Kawy
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/herbaty"
                  className="nav-link"
                  activeClassName="active"
                >
                  Herbaty
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/ekspresy"
                  className="nav-link"
                  activeClassName="active"
                >
                  Ekspresy
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/serwis"
                  className="nav-link"
                  activeClassName="active"
                >
                  Serwis
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/szkolenia"
                  className="nav-link"
                  activeClassName="active"
                >
                  Szkolenia
                </Link>
              </li>
            </ul>

            <Link to="/kontakt" className="button contact-button">
              Zapytaj o ofertę
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
}
